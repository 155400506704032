import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { Button, FormikControl } from "../../../Component";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import {
  fetchUpdateProfile,
  removeProfileData,
} from "../../../Redux/Profile/action";
import { fetchGetUserProfile } from "../../../Redux/UserProfile/action";
import * as Yup from "yup";

const PersonalDetailPopup = ({ setPersonalDetailPopup, profile }) => {
  const message = useSelector((state) => state.profile.message, shallowEqual);
  const errMessage = useSelector((state) => state.profile.errMsg, shallowEqual);

  const dispatch = useDispatch();
  const token = Cookies.get("token");

  const initialValues = {
    first_name: profile.first_name ? profile.first_name : "",
    last_name: profile.last_name ? profile.last_name : "",
    mobile: profile.user_mobile ? profile.user_mobile : "",
    address: profile.street_address ? profile.street_address : "",
    email: profile.user_email ? profile.user_email : "",
  };

  const validationSchema = Yup.object().shape({
    mobile: Yup.string()
      .matches(/^[0-9]+$/, "Phone number must only contain digits")
      .min(9, "Phone number is too short")
      .max(11, "Phone number is too long"),
  });

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    dispatch(fetchUpdateProfile(values, token));
    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
    }, 5000);
  };

  useEffect(() => {
    message && toast.success(message);
    message && dispatch(removeProfileData());
    message && dispatch(fetchGetUserProfile(token));
    message && setPersonalDetailPopup(false);
  }, [message]);

  useEffect(() => {
    errMessage && toast.success(errMessage);
    errMessage && dispatch(removeProfileData());
    errMessage && dispatch(fetchGetUserProfile(token));
    errMessage && setPersonalDetailPopup(false);
  }, [errMessage]);

  return (
    <div className="absolute top-10 border rounded-2xl z-40 bg-white shadow-2xl w-11/12 lg:w-3/4 left-[5%] lg:left-[10%] pt-5 md:pt-10 pb-16 ">
      <div className="w-11/12 xl:w-3/4 mx-auto">
        <h4 className="font-bold text-xl md:text-3xl text-primary text-center mb-8">
          Personal Detail
        </h4>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnMount
        >
          {(formik) => (
            <Form>
              <div className="w-full mt-10 2xl:flex items-center gap-x-5 justify-between">
                <div className="w-full">
                  <FormikControl
                    label="First Name"
                    control="input"
                    name="first_name"
                    placeholder="Enter Your First Name"
                  />
                </div>

                <div className="w-full mt-7 2xl:mt-0">
                  <FormikControl
                    label="Last Name"
                    control="input"
                    name="last_name"
                    placeholder="Enter Your Last Name"
                  />
                </div>
              </div>

              <div className="w-full mt-7">
                <FormikControl
                  label="Phone Number"
                  control="input"
                  name="mobile"
                  type="number"
                  readOnly={profile?.mobile_verified === "1" ? true : false}
                  placeholder="Enter Your Phone Number"
                />
              </div>

              <div className="w-full mt-7">
                <FormikControl
                  label="Email"
                  control="input"
                  name="email"
                  placeholder="Enter Your Email"
                  readOnly
                />
              </div>

              <div className="w-full mt-7">
                <FormikControl
                  label="Address"
                  control="input"
                  name="address"
                  placeholder="Enter Your Address"
                />
              </div>

              <div className="flex justify-between items-center gap-x-10 mt-8">
                <div
                  className="border w-full rounded-2xl text-primary cursor-pointer font-medium py-4 text-center "
                  style={{ background: "#EBF0FA" }}
                  onClick={() => setPersonalDetailPopup(false)}
                >
                  <Button value="Cancel" />
                </div>

                <div className="border w-full rounded-2xl bg-primary cursor-pointer text-white text-sm md:text-base font-medium py-4 text-center">
                  <Button value="Save Changes" type="submit" />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default PersonalDetailPopup;
