import React, { useEffect, useState } from "react";
import {
  fetchGetDefaultSearch,
  fetchGetExploreSearch,
  fetchGetInspectionData,
  fetchGetSearchResults,
  removeSearchData,
} from "../../Redux/Search/action";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Header from "./Header";
import Header2 from "./Header2";
import {
  Button,
  FilterMenu,
  Maps,
  Pagination,
  SearchCard,
  SinglePagnination,
} from "../../Component";
import { scrollToTop } from "../../Component/NavigateTop";
import SearchSkeleton from "./Skeleton";
import {
  fetchAddToWishlist,
  fetchRemoveWishlist,
  removeWishlistData,
} from "../../Redux/Wishlist/action";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { fetchGetBanner } from "../../Redux/Banner/action";

const SearchResult = () => {
  const [page, setPage] = useState(1);
  const [showList, setShowList] = useState(true);
  const [showMap, setShowMap] = useState(false);
  const [showInspection, setShowInspection] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [inspection, setInspection] = useState("");

  const searchVal = localStorage.getItem("Search-value");
  const val = JSON.parse(searchVal);

  const data = localStorage.getItem("search-property");
  const parsedData = JSON.parse(data);

  const [value, setValue] = useState(parsedData.lat ? "" : val);

  const token = Cookies.get("token");

  const navigate = useNavigate();

  // Get the current URL search parameters
  const searchParams = new URLSearchParams(window.location.search);

  // Read the value of the 'search' parameter
  const searchValue = searchParams.get("key");
  const propertyCategory = searchParams.get("property_category");
  const propTypes = searchParams.get("type");

  const dispatch = useDispatch();
  const loading = useSelector((state) => state.search.isloading, shallowEqual);
  const searchResults = useSelector(
    (state) => state.search.posts,
    shallowEqual
  );

  const message = useSelector((state) => state.wishlist.message, shallowEqual);
  const errMessage = useSelector(
    (state) => state.wishlist.errMsg,
    shallowEqual
  );
  const filters = useSelector((state) => state.search.filters, shallowEqual);
  const totalData = useSelector(
    (state) => state.search.totalData,
    shallowEqual
  );

  const banner = useSelector((state) => state.banner.banner, shallowEqual);

  const totalPage = Math.ceil(totalData / 8);

  const handleAddToWishlist = (id) => {
    scrollToTop();
    const body = {
      post_id: id,
    };
    dispatch(fetchAddToWishlist(body, token));
  };

  const handleRemoveWishlist = (id) => {
    scrollToTop();
    const body = {
      post_id: id,
    };
    dispatch(fetchRemoveWishlist(body, token));
  };

  console.log("check inspection", inspection);

  const inspectionFnc = () => {
    parsedData &&
      dispatch(
        fetchGetInspectionData(
          propertyCategory,
          inspection,
          parsedData?.address,
          parsedData.streetName,
          parsedData.suburb,
          parsedData.state,
          parsedData.bedroom,
          parsedData.bathroom,
          parsedData.parking,
          parsedData.type,
          parsedData.minPrice,
          parsedData.maxPrice,
          parsedData.minArea,
          parsedData.maxArea,
          page,
          token
        )
      );
  };

  useEffect(() => {
    scrollToTop();
    if (showInspection && inspection) {
      console.log("check if");
      inspectionFnc();
    } else {
      console.log("check else");
      parsedData && parsedData.lat && !parsedData.is_featured
        ? dispatch(
            fetchGetDefaultSearch(
              propertyCategory,
              parsedData.lat,
              parsedData.lng,
              parsedData.bedroom,
              parsedData.bathroom,
              parsedData.parking,
              parsedData.type,
              parsedData.minPrice,
              parsedData.maxPrice,
              parsedData.minArea,
              parsedData.maxArea,
              page,
              token
            )
          )
        : parsedData.is_featured
        ? dispatch(
            fetchGetExploreSearch(
              propertyCategory,
              parsedData.lat,
              parsedData.lng,
              parsedData.is_featured === 1 ? 1 : 2,
              parsedData.bedroom,
              parsedData.bathroom,
              parsedData.parking,
              parsedData.type,
              parsedData.minPrice,
              parsedData.maxPrice,
              parsedData.minArea,
              parsedData.maxArea,
              page,
              token
            )
          )
        : dispatch(
            fetchGetSearchResults(
              propertyCategory,
              parsedData.address,
              parsedData.streetName,
              parsedData.suburb,
              parsedData.state,
              parsedData.bedroom,
              parsedData.bathroom,
              parsedData.parking,
              parsedData.type,
              parsedData.minPrice,
              parsedData.maxPrice,
              parsedData.minArea,
              parsedData.maxArea,
              page,
              token
            )
          );
    }

    return () => {
      dispatch(removeSearchData());
    };
  }, [page, inspection]);

  // banner api
  useEffect(() => {
    parsedData && (parsedData.state || parsedData.suburb)
      ? dispatch(
          fetchGetBanner(
            parsedData.state,
            parsedData.suburb,
            propertyCategory === "buy" ? "1166" : "1167"
          )
        )
      : dispatch(fetchGetBanner(propertyCategory === "buy" ? "1166" : "1167"));
  }, []);

  useEffect(() => {
    message && dispatch(removeSearchData());
    message && toast.success(message);
    message && dispatch(removeWishlistData());
    message && parsedData && parsedData.lat && !parsedData.is_featured
      ? dispatch(
          fetchGetDefaultSearch(
            propertyCategory,
            parsedData.lat,
            parsedData.lng,
            parsedData.bedroom,
            parsedData.bathroom,
            parsedData.parking,
            parsedData.type,
            parsedData.minPrice,
            parsedData.maxPrice,
            parsedData.minArea,
            parsedData.maxArea,
            page,
            token
          )
        )
      : message && parsedData.is_featured
      ? dispatch(
          fetchGetExploreSearch(
            propertyCategory,
            parsedData.lat,
            parsedData.lng,
            parsedData.is_featured === 1 ? 1 : 2,
            parsedData.bedroom,
            parsedData.bathroom,
            parsedData.parking,
            parsedData.type,
            parsedData.minPrice,
            parsedData.maxPrice,
            parsedData.minArea,
            parsedData.maxArea,
            page,
            token
          )
        )
      : message &&
        dispatch(
          fetchGetSearchResults(
            propertyCategory,
            parsedData.address,
            parsedData.streetName,
            parsedData.suburb,
            parsedData.state,
            parsedData.bedroom,
            parsedData.bathroom,
            parsedData.parking,
            parsedData.type,
            parsedData.minPrice,
            parsedData.maxPrice,
            parsedData.minArea,
            parsedData.maxArea,
            page,
            token
          )
        );
  }, [message]);

  useEffect(() => {
    errMessage && dispatch(removeSearchData());
    errMessage && toast.error(errMessage);
    errMessage && dispatch(removeWishlistData());
    errMessage && parsedData && parsedData.lat && !parsedData.is_featured
      ? dispatch(
          fetchGetDefaultSearch(
            propertyCategory,
            parsedData.lat,
            parsedData.lng,
            parsedData.bedroom,
            parsedData.bathroom,
            parsedData.parking,
            parsedData.type,
            parsedData.minPrice,
            parsedData.maxPrice,
            parsedData.minArea,
            parsedData.maxArea,
            page,
            token
          )
        )
      : errMessage && parsedData.is_featured
      ? dispatch(
          fetchGetExploreSearch(
            propertyCategory,
            parsedData.lat,
            parsedData.lng,
            parsedData.is_featured === 1 ? 1 : 2,
            parsedData.bedroom,
            parsedData.bathroom,
            parsedData.parking,
            parsedData.type,
            parsedData.minPrice,
            parsedData.maxPrice,
            parsedData.minArea,
            parsedData.maxArea,
            page,
            token
          )
        )
      : errMessage &&
        dispatch(
          fetchGetSearchResults(
            propertyCategory,
            parsedData.address,
            parsedData.streetName,
            parsedData.suburb,
            parsedData.state,
            parsedData.bedroom,
            parsedData.bathroom,
            parsedData.parking,
            parsedData.type,
            parsedData.minPrice,
            parsedData.maxPrice,
            parsedData.minArea,
            parsedData.maxArea,
            page,
            token
          )
        );
  }, [errMessage]);

  if (loading) {
    return <SearchSkeleton />;
  }

  return (
    <div className="py-16 sm:py-24 border h-full relative">
      {showFilter && (
        <div
          className="w-full h-full bg-black z-50 top-0 absolute opacity-10"
          onClick={() => setShowFilter(false)}
        ></div>
      )}
      <FilterMenu
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        value={value}
        setValue={setValue}
        filters={filters}
        filterData={parsedData}
        searchValue={searchValue}
        propTypes={propTypes}
      />
      <div className="w-11/12 xl:w-3/4 mx-auto">
        <Header
          showMap={showMap}
          setShowList={setShowList}
          setShowMap={setShowMap}
          searchValue={searchValue}
          propertyCategory={propertyCategory}
          setShowFilter={setShowFilter}
          setValue={setValue}
          value={value}
          filterData={parsedData}
          page={page}
          setInspection={setInspection}
        />
      </div>
      <hr className="mt-6" />
      <div className="grid grid-cols-12 sm:gap-x-10 xl:gap-x-16 mt-10 w-11/12 xl:w-3/4 mx-auto">
        <div
          className={`${
            showMap ? "hidden" : "block"
          } col-span-12 md:col-span-6 lg:col-span-8 xl:col-span-8`}
        >
          <Header2
            showInspection={showInspection}
            setShowInspection={setShowInspection}
            showList={showList}
            setShowList={setShowList}
            showMap={showMap}
            setShowMap={setShowMap}
            data={searchResults}
            searchValue={searchValue}
            propertyCategory={propertyCategory}
            totalData={totalData}
            page={page}
            filterData={parsedData}
            inspection={inspection}
            setInspection={setInspection}
          />
          {showInspection ? (
            <div className="w-full mt-10 border">
              <div className="w-full pb-5 overflow-x-auto flex items-center mt-5 gap-x-5">
                <div
                  onClick={() => setInspection("Today")}
                  className={`${
                    inspection === "Today"
                      ? "border-primary text-primary bg-purple-50"
                      : "text-textPrimary"
                  } border lg:p-5 p-10 w-24 h-24 flex flex-col justify-center cursor-pointer transition duration-1000 ease-in-out hover:bg-purple-50 items-center rounded-full`}
                >
                  <p className="font-medium text-sm">Today</p>
                </div>

                <div
                  onClick={() => setInspection("Tomorrow")}
                  className={`${
                    inspection === "Tomorrow"
                      ? "border-primary text-primary bg-purple-50"
                      : "text-textPrimary"
                  } border lg:p-5 p-10 w-24 h-24 flex flex-col justify-center cursor-pointer transition duration-1000 ease-in-out hover:bg-purple-50 items-center rounded-full`}
                >
                  <p className="font-medium text-sm">Tomorrow</p>
                </div>

                <div
                  onClick={() => setInspection("This week")}
                  className={`${
                    inspection === "This week"
                      ? "border-primary text-primary bg-purple-50"
                      : "text-textPrimary"
                  } border lg:p-5 p-10 w-24 h-24 flex flex-col justify-center cursor-pointer transition duration-1000 ease-in-out hover:bg-purple-50 items-center rounded-full`}
                >
                  <p className="font-medium text-sm">Week</p>
                </div>

                <div
                  onClick={() => setInspection("Month")}
                  className={`${
                    inspection === "Month"
                      ? "border-primary text-primary bg-purple-50"
                      : "text-textPrimary"
                  } border lg:p-5 p-10 w-24 h-24 flex flex-col justify-center cursor-pointer transition duration-1000 ease-in-out hover:bg-purple-50 items-center rounded-full`}
                >
                  <p className="font-medium text-sm">Month</p>
                </div>
              </div>
            </div>
          ) : null}
          <div className="mt-10 w-full">
            <SearchCard
              data={searchResults}
              handleAddToWishlist={handleAddToWishlist}
              handleRemoveWishlist={handleRemoveWishlist}
            />
            <div className="mt-10">
              {totalPage > 5 ? (
                <Pagination
                  setPage={setPage}
                  page={page}
                  totalData={totalData}
                  totalPage={totalPage}
                />
              ) : (
                <SinglePagnination
                  setPage={setPage}
                  page={page}
                  totalData={totalData}
                  totalPage={totalPage}
                />
              )}
            </div>
          </div>
        </div>
        <div
          className={`${
            showMap
              ? "col-span-12 h-[50vh] sm:h-[70vh] lg:h-[90vh] 2xl:h-[100vh]"
              : "col-span-12 md:col-start-7 lg:col-start-9 md:col-end-13 h-fit"
          } mt-10 md:mt-0  `}
        >
          {showList || showInspection ? (
            <>
              <div className="border rounded-lg w-full  p-5 ">
                <h5 className="text-2xl font-bold text-textPrimary">
                  Didn’t find what you are looking for?
                </h5>
                <p className="text-textSecondary mt-1">
                  Get help from the experts today!
                </p>
                <Button
                  value="Make a Quick Enquiry"
                  handleClick={() => navigate("/agent-enquiry")}
                  className="bg-primary text-white w-full rounded-lg py-3 mt-7"
                />
              </div>

              {/* banner */}
              {banner && banner.length ? (
                <div className="h-full w-full border mt-10 rounded-xl overflow-hidden">
                  <a href={banner?.[0]?.url} target="_blank">
                    <img
                      src={banner?.[0]?.image}
                      alt="banner"
                      className="w-full h-full object-fill"
                    />
                  </a>
                </div>
              ) : null}
            </>
          ) : null}

          {showMap && (
            <div className="w-full h-full">
              <Maps products={searchResults} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchResult;
