import axios from "axios";
import { BaseUrl2 } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const removeTermsPrivacy = () => ({
  type: ActionTypes.REMOVE_TERMS_PRIVACY_DATA,
});

export const fetchGetPrivacyAndPolicy = () => async (dispatch) => {
  await axios.get(`${BaseUrl2}/deal/page?page_id=10`).then(({ data }) => {
    dispatch({
      type: ActionTypes.GET_PRIVACY_POLICIES,
      payload: data,
    });
  });
};

export const fetchGetTermsofUse = () => async (dispatch) => {
  await axios.get(`${BaseUrl2}/deal/page?page_id=9`).then(({ data }) => {
    dispatch({
      type: ActionTypes.GET_TERMS_OF_USE,
      payload: data,
    });
  });
};
