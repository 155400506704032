import React from "react";
import { useState } from "react";
import { MyInspectionCard, MyInspectionCardSkeleton } from "../../Component";
import Cookies from "js-cookie";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  fetchGetMyInspection,
  removeProfileData,
} from "../../Redux/Profile/action";
import { toast } from "react-toastify";
import MyInspectionSkeleton from "./MyInspectionSkeleton";

const MyInspection = () => {
  const [toggle, setToggle] = useState({
    all: true,
    today: false,
    tomorrow: false,
    week: false,
    month: false,
  });

  const allInspection = useSelector(
    (state) => state.profile.allInspection,
    shallowEqual
  );

  const [inspections, setInspection] = useState(allInspection);
  const todayInspection = useSelector(
    (state) => state.profile.todayInspection,
    shallowEqual
  );
  const tomorrowInspection = useSelector(
    (state) => state.profile.tomorrowInspection,
    shallowEqual
  );
  const weekInspection = useSelector(
    (state) => state.profile.weekInspection,
    shallowEqual
  );
  const monthInspection = useSelector(
    (state) => state.profile.monthInspection,
    shallowEqual
  );

  const [all, setAll] = useState();
  const [today, setToday] = useState();
  const [tomm, setTomm] = useState();
  const [week, setWeek] = useState();
  const [month, setMonth] = useState();

  const dispatch = useDispatch();

  const token = Cookies.get("token");

  const loading = useSelector((state) => state.profile.isloading, shallowEqual);

  const message = useSelector((state) => state.profile.message, shallowEqual);
  const errMessage = useSelector((state) => state.profile.errMsg, shallowEqual);

  useEffect(() => {
    setInspection(allInspection);
    setToggle({
      all: true,
      today: false,
      tomorrow: false,
      week: false,
      month: false,
    });
  }, [allInspection]);

  const handleClickAll = () => {
    setToggle({
      all: true,
      today: false,
      tomorrow: false,
      week: false,
      month: false,
    });

    setInspection(allInspection);
  };

  const handleClickToday = () => {
    setToggle({
      all: false,
      today: true,
      tomorrow: false,
      week: false,
      month: false,
    });

    setInspection(todayInspection);
  };

  const handleClickTomorrow = () => {
    setToggle({
      all: false,
      today: false,
      tomorrow: true,
      week: false,
      month: false,
    });

    setInspection(tomorrowInspection);
  };

  const handleClickWeek = () => {
    setToggle({
      all: false,
      today: false,
      tomorrow: false,
      week: true,
      month: false,
    });

    setInspection(weekInspection);
  };

  const handleClickMonth = () => {
    setToggle({
      all: false,
      today: false,
      tomorrow: false,
      week: false,
      month: true,
    });
    setInspection(monthInspection);
  };

  useEffect(() => {
    const abc = allInspection.map((val) =>
      val?.inspection_times.map((itm) => {
        return itm;
      })
    );

    const abc2 = tomorrowInspection.map((val) =>
      val?.inspection_times?.map((itm) => {
        return itm;
      })
    );

    console.log("abccccccccccccccccccccc", abc2);
  }, [allInspection]);

  useEffect(() => {
    message && toast.success(message);
    message && dispatch(removeProfileData());
    setTimeout(() => {
      message && dispatch(fetchGetMyInspection(token));
    }, 1000);
  }, [message]);

  useEffect(() => {
    errMessage && toast.error(errMessage);
    errMessage && dispatch(removeProfileData());
    setTimeout(() => {
      errMessage && dispatch(fetchGetMyInspection());
    }, 1000);
  }, [errMessage]);

  useEffect(() => {
    dispatch(fetchGetMyInspection(token));
    return () => {
      dispatch(removeProfileData());
    };
  }, []);

  if (loading) {
    return (
      <div className="w-full h-full">
        <MyInspectionSkeleton />
      </div>
    );
  }

  return (
    <div className="w-full h-full">
      <div className="w-full h-fit border bg-white p-5 rounded-xl">
        <h5 className="font-semibold text-2xl">Inspection Plans</h5>
        <hr className="mt-5" />
        <div className="w-full pb-5 overflow-x-auto flex items-center mt-5 gap-x-5">
          <div
            onClick={handleClickAll}
            className={`${
              toggle.all
                ? "border-primary text-primary bg-purple-50"
                : "text-textPrimary"
            } border lg:p-5 p-10 w-24 h-24 flex flex-col justify-center cursor-pointer transition duration-1000 ease-in-out hover:bg-purple-50 items-center rounded-full`}
          >
            <p className="font-medium text-sm">All</p>
            <p className="font-semibold text-base mt-1">
              {allInspection && allInspection.length ? allInspection.length : 0}
            </p>
          </div>

          <div
            onClick={handleClickToday}
            className={`${
              toggle.today
                ? "border-primary text-primary bg-purple-50"
                : "text-textPrimary"
            } border lg:p-5 p-10 w-24 h-24 flex flex-col justify-center cursor-pointer transition duration-1000 ease-in-out hover:bg-purple-50 items-center rounded-full`}
          >
            <p className="font-medium text-sm">Today</p>
            <p className="font-semibold text-base mt-1">
              {todayInspection && todayInspection.length
                ? todayInspection.length
                : 0}
            </p>
          </div>

          <div
            onClick={handleClickTomorrow}
            className={`${
              toggle.tomorrow
                ? "border-primary text-primary bg-purple-50"
                : "text-textPrimary"
            } border lg:p-5 p-10 w-24 h-24 flex flex-col justify-center cursor-pointer transition duration-1000 ease-in-out hover:bg-purple-50 items-center rounded-full`}
          >
            <p className="font-medium text-sm">Tomorrow</p>
            <p className="font-semibold text-base mt-1">
              {tomorrowInspection && tomorrowInspection.length
                ? tomorrowInspection.length
                : 0}
            </p>
          </div>

          <div
            onClick={handleClickWeek}
            className={`${
              toggle.week
                ? "border-primary text-primary bg-purple-50"
                : "text-textPrimary"
            } border lg:p-5 p-10 w-24 h-24 flex flex-col justify-center cursor-pointer transition duration-1000 ease-in-out hover:bg-purple-50 items-center rounded-full`}
          >
            <p className="font-medium text-sm">Week</p>
            <p className="font-semibold text-base mt-1">
              {weekInspection && weekInspection.length
                ? weekInspection.length
                : 0}
            </p>
          </div>

          <div
            onClick={handleClickMonth}
            className={`${
              toggle.month
                ? "border-primary text-primary bg-purple-50"
                : "text-textPrimary"
            } border lg:p-5 p-10 w-24 h-24 flex flex-col justify-center cursor-pointer transition duration-1000 ease-in-out hover:bg-purple-50 items-center rounded-full`}
          >
            <p className="font-medium text-sm">Month</p>
            <p className="font-semibold text-base mt-1">
              {monthInspection && monthInspection.length
                ? monthInspection.length
                : 0}
            </p>
          </div>
        </div>
      </div>

      <div className="w-full h-full mt-5 ">
        <MyInspectionCard myInspection={inspections} />
      </div>
    </div>
  );
};

export default MyInspection;
