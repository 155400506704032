import React, { useEffect, useMemo, useState } from "react";
import { Home } from "../../Asset/Assest";
import Button from "../../Component/Button/Button";
import { Filter, Search } from "../../Component";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchGetDefaultSearch,
  fetchGetSearchResults,
} from "../../Redux/Search/action";
import Cookies from "js-cookie";
import debounce from "lodash.debounce";
import {
  fetchGetAgentAgencySearchSuggestion,
  removeAgentAgencyData,
} from "../../Redux/Agent-Agencies/action";

const Header = ({
  activeBuy,
  activeRent,
  activeAgent,
  activeAgency,
  handleClickBuy,
  handleClickRent,
  handleClickAgent,
  handleClickAgency,
  propertyCategory,
  setShowFilter,
  value,
  setValue,
}) => {
  const [propertyAddress, SetPropertyAddress] = useState([]);
  const [propertyState, setPropertyState] = useState([]);
  const [propertyStreetName, setPropertyStreetName] = useState([]);
  const [propertySuburb, setPropertySuburb] = useState([]);

  const [searchValue, setSearchValue] = useState("");

  const suggestions = useSelector(
    (state) => state.agentagencies.suggestions,
    shallowEqual
  );

  const suggestAgent = suggestions.filter(
    (val) => val.type === "Agent" || val.type === "Address"
  );

  const suggestAgency = suggestions.filter(
    (val) => val.type === "Agency" || val.type === "Address"
  );

  const handleClickAgencySuggestion = (name) => {
    navigate(`/find-agencies/agencies?search=${name}`);
  };

  const handleClickAgentSuggestion = (name) => {
    navigate(`/find-agents/agents?search=${name}`);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = Cookies.get("token");

  const onchangeSearchHandler = (e) => {
    setSearchValue(e.target.value);
  };

  const debouncedResults = useMemo(() => {
    return debounce(onchangeSearchHandler, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  useEffect(() => {
    dispatch(fetchGetAgentAgencySearchSuggestion(searchValue));
    return () => {
      dispatch(removeAgentAgencyData());
    };
  }, [searchValue]);

  const getLocationData = async () => {
    if (value.length > 0) {
      const search = value.map((val) => val.label);

      const promises = search.map(async (item) => {
        return await geocodeByAddress(item);
      });
      const geocodes = await Promise.all(promises);
      const description = geocodes.map((item) => item[0]);

      // property address
      description.map((val, i) => {
        return SetPropertyAddress((pre) => [...pre, val.formatted_address]);
      });

      // property state
      description.map((val) => {
        const data = val.address_components.filter((k) =>
          k.types.includes("administrative_area_level_1")
        );

        data.map((val) => {
          return setPropertyState((pre) => [...pre, val.short_name]);
        });
      });

      // property street name
      description.map((val) => {
        const data = val.address_components.filter((k) =>
          k.types.includes("route")
        );

        data.map((val) => {
          return setPropertyStreetName((pre) => [...pre, val.long_name]);
        });
      });

      // property Suburb
      description.map((val) => {
        const data = val.address_components.filter((k) =>
          k.types.includes(
            "locality" || "political" || "administrative_area_level_2"
          )
        );

        data.map((val) => {
          return setPropertySuburb((pre) => [...pre, val.long_name]);
        });
      });

      localStorage.setItem("Search-value", JSON.stringify(value));
    } else {
      dispatch(
        fetchGetDefaultSearch(
          propertyCategory,
          25.2744,
          133.7751,
          null,
          null,
          null,
          [],
          null,
          null,
          null,
          null,
          1,
          token
        )
      );
      navigate(`/search?property_category=${propertyCategory}`);
    }
  };

  if (
    (propertyAddress && propertyAddress.length) ||
    (propertyState && propertyState.length) ||
    (propertyStreetName && propertyStreetName.length) ||
    (propertySuburb && propertySuburb.length)
  ) {
    dispatch(
      fetchGetSearchResults(
        propertyCategory,
        propertyAddress,
        propertyStreetName,
        propertySuburb,
        propertyState,
        null,
        null,
        null,
        [],
        null,
        null,
        null,
        null,
        1,
        token
      )
    );

    navigate(
      `/search?property_category=${propertyCategory}&key=${propertyAddress}`
    );
  }

  return (
    <div className="w-full relative">
      <div className="h[580px] xl:h-[610px] w-full">
        <img src={Home} alt="banner" className="w-full h-full" />
      </div>

      <div className="absolute w-full md:w-[700px] lg:w-[750px] xl:w-[900px] border xl:translate-x-1/4 2xl:translate-x-1/3 bg-white rounded-lg md:shadow-md top-0 sm:top-5 md:top-8 lg:top-16 xl:top-20 px-7 py-10 homeContainer">
        <h4 className="text-textPrimary font-semibold text-lg md:text-2xl xl:text-3xl">
          Search Property for Sale
        </h4>

        <div className="border-b flex items-center gap-x-3 md:gap-x-6 mt-5 md:mt-12">
          <h6
            className={`${
              activeBuy
                ? "text-primary border-primary border-b-2 font-semibold"
                : "text-textSecondary"
            }  text-xs md:text-base lg:text-lg xl:text-xl px-3 md:px-6 py-3 w-fit cursor-pointer`}
            onClick={handleClickBuy}
          >
            Buy
          </h6>
          <h6
            className={`${
              activeRent
                ? "text-primary border-primary border-b-2 font-semibold"
                : "text-textSecondary"
            }  text-xs md:text-base lg:text-lg xl:text-xl px-3 md:px-6 py-3 w-fit cursor-pointer`}
            onClick={handleClickRent}
          >
            Rent
          </h6>
          <h6
            className={`${
              activeAgent
                ? "text-primary border-primary border-b-2 font-semibold"
                : "text-textSecondary"
            }  text-xs md:text-base lg:text-lg xl:text-xl px-3 md:px-6 py-3 w-fit cursor-pointer`}
            onClick={handleClickAgent}
          >
            Find Agent
          </h6>
          <h6
            className={`${
              activeAgency
                ? "text-primary border-primary border-b-2 font-semibold"
                : "text-textSecondary"
            }  text-xs md:text-base lg:text-lg xl:text-xl px-3 md:px-6 py-3 w-fit cursor-pointer`}
            onClick={handleClickAgency}
          >
            Find Agencies
          </h6>
        </div>

        {activeBuy || activeRent ? (
          <div className="mt-8 flex items-center flex-wrap md:flex-nowrap  justify-between">
            <div className="bg-white w-full">
              <GooglePlacesAutocomplete
                placeholder="Where is it?"
                textInputProps={{
                  placeholderTextColor: "#32a852",
                  returnKeyType: "search",
                }}
                autocompletionRequest={{
                  componentRestrictions: { country: ["au"] },
                }}
                selectProps={{
                  value,
                  onChange: setValue,
                  isMulti: true,
                }}
              />
            </div>

            <div className="w-full flex items-center mt-5 md:mt-0 md:justify-end gap-x-4 md:gap-x-6">
              <Filter
                handleClick={() => setShowFilter(true)}
                className="border border-primary text-primary rounded-2xl text-xs sm:text-sm w-fit px-7 md:px-10 py-2 md:py-3.5 flex items-center gap-x-3 justify-center cursor-pointer transition duration-1000 ease-in-out hover:bg-orange-50"
              />

              <Button
                value="Search"
                className="bg-primary py-2 md:py-3.5 px-5 w-fit text-sm md:text-base md:px-10 rounded-xl text-white"
                handleClick={getLocationData}
              />
            </div>
          </div>
        ) : (
          <div className="mt-8 flex items-center gap-x-10 flex-wrap md:flex-nowrap  justify-between">
            <div className="w-full relative">
              <Search
                placeholder="Search..."
                // searchValue={searchValue}
                onchangeSearchHandler={debouncedResults}
                handleClickSuggestion={
                  activeAgent
                    ? handleClickAgentSuggestion
                    : handleClickAgencySuggestion
                }
              />
              {(suggestAgent && suggestAgent.length) ||
              (suggestAgency && suggestAgency.length) ? (
                <div className="w-full absolute border h-40 overflow-y-auto bg-white mt-1 rounded-xl">
                  {activeAgent &&
                    suggestAgent &&
                    suggestAgent.map((val) => {
                      return (
                        <h6
                          key={val.id}
                          className="px-5 py-2.5 cursor-pointer hover:bg-slate-50 text-sm text-textPrimary"
                          onClick={() => handleClickAgentSuggestion(val.name)}
                        >
                          {val.name}
                        </h6>
                      );
                    })}

                  {activeAgency && suggestAgency
                    ? suggestAgency.map((val) => {
                        return (
                          <h6
                            key={val.id}
                            className="px-5 py-2.5 cursor-pointer hover:bg-slate-50 text-sm text-textPrimary"
                            onClick={() =>
                              handleClickAgencySuggestion(val.name)
                            }
                          >
                            {val.name}
                          </h6>
                        );
                      })
                    : null}
                </div>
              ) : null}
            </div>
            <Button
              handleClick={() =>
                activeAgent
                  ? navigate(`/find-agents/agents?search=${searchValue}`)
                  : navigate(`/find-agencies/agencies?search=${searchValue}`)
              }
              value="Search"
              className="bg-primary py-2 mt-5 md:mt-0 md:py-3.5 px-5 w-fit text-sm md:text-base md:px-10 rounded-xl text-white"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
