import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Footer, Header } from "./Component";
import {
  AdvertiseWithUs,
  Agencies,
  AgencyDetailPage,
  Agent,
  AgentDetailPage,
  AgentEnquiries,
  AgentEnquiry,
  AgentRequest,
  ChangePassword,
  ContactUs,
  ForgotPassword,
  Home,
  MyEnquiry,
  MyInbox,
  MyInspection,
  MySavedSearches,
  NewsDetail,
  NewsPage,
  OfferMessages,
  PrivacyPolicy,
  Profile,
  PropertyDetail,
  Rent,
  SavedProperty,
  SearchAgency,
  SearchAgent,
  SearchResult,
  Signin,
  Signup,
  Sold,
  TermsofUse,
  VerifyAccount,
} from "./Pages";
import AuthRoute from "./Layout/AuthRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AccountRoute from "./Layout/Account";
import { fetchGetUserProfile } from "./Redux/UserProfile/action";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { fetchGetNotification } from "./Redux/Notification/action";

const App = () => {
  const dispatch = useDispatch();
  const token = Cookies.get("token");

  useEffect(() => {
    token && dispatch(fetchGetUserProfile(token));
    token && dispatch(fetchGetNotification(1, token));
  }, [token]);

  return (
    <div className="bg-white box-border min-h-screen relative">
      <BrowserRouter>
        <Header />
        <ToastContainer />
        <Routes>
          <Route path="/auth" element={<AuthRoute />}>
            <Route path="sign-in" element={<Signin />} />
            <Route path="sign-up" element={<Signup />} />
            <Route path="verify" element={<VerifyAccount />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="change-password" element={<ChangePassword />} />
          </Route>

          <Route path="/" element={<Home />} />
          <Route path="/rent" element={<Rent />} />
          <Route path="/sold/leased" element={<Sold />} />
          <Route path="/search" element={<SearchResult />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/news/:id" element={<NewsDetail />} />
          <Route path="/find-agents" element={<Agent />} />
          <Route path="/find-agencies" element={<Agencies />} />
          <Route path="/find-agents/agents" element={<SearchAgent />} />
          <Route path="/find-agencies/agencies" element={<SearchAgency />} />
          <Route path="/property-detail" element={<PropertyDetail />} />
          <Route path="/agent-enquiry" element={<AgentEnquiry />} />
          <Route path="/agent-detail" element={<AgentDetailPage />} />
          <Route path="/agency-detail" element={<AgencyDetailPage />} />

          <Route path="/my-inbox" element={<MyInbox />} />

          <Route path="contact-us" element={<ContactUs />} />
          <Route path="advertise-with-us" element={<AdvertiseWithUs />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-of-use" element={<TermsofUse />} />

          {/* profile routes */}
          <Route path="/me" element={<AccountRoute Component={Profile} />}>
            <Route path="agent-enquiry" element={<MyEnquiry />} />
            <Route path="my-enquiry" element={<OfferMessages />} />

            <Route path="saved-properties" element={<SavedProperty />} />
            <Route path="agent-enquiries" element={<AgentEnquiries />} />
            <Route path="inspections-list" element={<MyInspection />} />
            <Route path="saved-searches" element={<MySavedSearches />} />
            <Route path="agent-request" element={<AgentRequest />} />
          </Route>
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;
