import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const loading = () => ({
  type: ActionTypes.WISHLIST_LOADING,
});

export const removeWishlistData = () => ({
  type: ActionTypes.REMOVE_WISHLIST_DATA,
});

export const fetchAddToWishlist = (id, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/add_wishlist`, id, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.ADD_TO_WISHLIST_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.ADD_TO_WISHLIST_FAIL,
        payload: e.response.data,
      });
    });
};

export const fetchRemoveWishlist = (id, token) => async (dispatch) => {
  await axios
    .post(`${BaseUrl}/delete_wishlist_withpostid`, id, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.REMOVE_WISHLIST_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.REMOVE_WISHLIST_FAIL,
        payload: e.response.data,
      });
    });
};
