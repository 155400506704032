import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const loading = () => ({
  type: ActionTypes.SOLD_LOADING,
});

export const removeSoldData = () => ({
  type: ActionTypes.REMOVE_SOLD_DATA,
});

export const fetchGetSoldProperties = (page, token) => async (dispatch) => {
  await axios
    .get(
      `${BaseUrl}/ads_list?slug=real-estate&api_from=classiEstate&realestate_type=Commercial&is_completed=1&page=${page}`,
      token && token !== undefined
        ? { headers: { Authorization: `Bearer ${token}` } }
        : null
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_SOLD_PROPERTIES,
        payload: data,
      });
    });
};
