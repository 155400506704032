import * as ActionTypes from "./actionTypes";

const initialState = {
  isloading: true,
  success: false,
  message: "",
  errMsg: "",
  verifyMsg: "",
  verifyErr: "",
  otpSucc: "",
  otpErr: "",
  totalData: "",
  savedProperty: [],
  agentEnquiries: [],
  enquiryDetail: "",
  allInspection: [],
  todayInspection: [],
  tomorrowInspection: [],
  weekInspection: [],
  monthInspection: [],
};

export const Profile = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_SAVED_PROPERTY:
      return {
        ...state,
        isloading: false,
        success: true,
        savedProperty: action.payload,
      };

    case ActionTypes.FAIL_GET_SAVED_PROPERTY:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.GET_AGENT_ENQUIRIES:
      return {
        ...state,
        isloading: false,
        success: true,
        agentEnquiries: action.payload.enquiries,
        totalData: action.payload.total_number,
      };

    case ActionTypes.GET_MY_INSPECTION:
      return {
        ...state,
        isloading: false,
        success: true,
        allInspection: action.payload.inspections,
        todayInspection: action.payload.today_inspection,
        tomorrowInspection: action.payload.tomorrow_inspection,
        weekInspection: action.payload.week_inspection,
        monthInspection: action.payload.month_inspection,
      };

    case ActionTypes.DELETE_INSPECTION_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: "Inspection plan remove successfully",
      };

    case ActionTypes.DELETE_INSPECTION_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.ADD_INSPECTION_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.ADD_INSPECTION_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.UPLOAD_PROFILE_SUCCESSS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.UPLOAD_PROFILE_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: false,
        message: action.payload.message,
      };

    case ActionTypes.UPDATE_PROFILE_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.VERIFY_MOBILE_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        verifyMsg: action.payload.message,
      };

    case ActionTypes.VERIFY_MOBILE_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        verifyErr: action.payload.message,
      };

    case ActionTypes.VERIFY_CODE_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        otpSucc: action.payload.message,
      };

    case ActionTypes.VERIFY_CODE_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        otpErr: action.payload.message,
      };

    case ActionTypes.AGENT_REQUEST_SUCCESS:
      return {
        ...state,
        isloading: false,
        success: true,
        message: action.payload.message,
      };

    case ActionTypes.AGENT_REQUEST_FAIL:
      return {
        ...state,
        isloading: false,
        success: false,
        errMsg: action.payload.message,
      };

    case ActionTypes.REMOVE_PROILE_DATA:
      return {
        isloading: true,
        success: false,
        message: "",
        errMsg: "",
        verifyMsg: "",
        verifyErr: "",
        otpSucc: "",
        otpErr: "",
        totalData: "",
        savedProperty: [],
        agentEnquiries: [],
        enquiryDetail: "",
        allInspection: [],
        todayInspection: [],
        tomorrowInspection: [],
        weekInspection: [],
        monthInspection: [],
      };

    default:
      return state;
  }
};
