import React, { useEffect, useState } from "react";
import StartComponent from "./StartComponent";
import LocationCompo from "./LocationCompo";
import CommonCompo from "./CommonCompo";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchGetEnquiryOptions,
  fetchSendAgentEnquiry,
  removeEnquiryData,
} from "../../Redux/Enquiry/action";
import Loading from "../../Component/Loading/Loading";
import NextCommonCompo from "./NextCommonCompo";
import PropertyType from "./PropertyType";
import { scrollToTop } from "../../Component/NavigateTop";
import PersonalDetail from "./PersonalDetail";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { geocodeByAddress } from "react-google-places-autocomplete";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

const AgentEnquiry = () => {
  const [startCompo, setStartCompo] = useState(true);
  const [locationCompo, setLocationCompo] = useState(false);
  const [enquiryCompo, setEnquiryCompo] = useState(false);
  const [natureComponent, setNatureComponent] = useState(false);
  const [lookingforCompo, setLookingforCompo] = useState(false);
  const [propertyCondCompo, setPropertyCondCompo] = useState(false);
  const [propertyTypeCompo, setPropertyTypeCompo] = useState(false);
  const [priceCompo, setPriceCompo] = useState(false);
  const [landCompo, setLandCompo] = useState(false);
  const [houseCompo, setHouseCompo] = useState(false);
  const [needCompo, setNeedCompo] = useState(false);
  const [personalDetailCompo, setpersonalDetailCompo] = useState(false);

  const [value, setValue] = useState("");
  const [propertyAddress, SetPropertyAddress] = useState([]);
  const [propertyState, setPropertyState] = useState([]);
  const [propertyStreetName, setPropertyStreetName] = useState([]);
  const [propertySuburb, setPropertySuburb] = useState([]);

  const [enquiryFor, setEnquiryFor] = useState("");
  const [propertyNature, setPropertyNature] = useState("");
  const [lookFor, setLookFor] = useState("");
  const [propertyCondition, setPropertyCondition] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [price, setPrice] = useState("");
  const [landSize, setLandSize] = useState("");
  const [houseSize, setHouseSize] = useState("");
  const [needs, setNeeds] = useState("");

  const dispath = useDispatch();

  const loading = useSelector((state) => state.enquiry.isloading, shallowEqual);
  const options = useSelector((state) => state.enquiry.options, shallowEqual);

  const message = useSelector((state) => state.enquiry.message, shallowEqual);
  const errMsg = useSelector((state) => state.enquiry.errMsg, shallowEqual);

  const profile = useSelector((state) => state.profiles.profile, shallowEqual);

  const nature = [];
  const lookingFor = [];

  const token = Cookies.get("token");

  enquiryFor &&
    nature.push(options.enquiry_for?.find((val) => val.label === enquiryFor));

  nature &&
    propertyNature &&
    lookingFor.push(
      nature?.[0]?.child.find((val) => val.label === propertyNature)
    );

  // location button click
  const getLocationData = async () => {
    if (value && value.length) {
      const search = value.map((val) => val.label);

      const promises = search.map(async (item) => {
        return await geocodeByAddress(item);
      });
      const geocodes = await Promise.all(promises);
      const description = geocodes.map((item) => item[0]);

      // property address
      description.map((val, i) => {
        return SetPropertyAddress((pre) => [
          ...new Set([...pre, val.formatted_address]),
        ]);
      });

      // property state
      description.map((val) => {
        const data = val.address_components.filter((k) =>
          k.types.includes("administrative_area_level_1")
        );

        data.map((val) => {
          return setPropertyState((pre) => [
            ...new Set([...pre, val.short_name]),
          ]);
        });
      });

      // property street name
      description.map((val) => {
        const data = val.address_components.filter((k) =>
          k.types.includes("route")
        );

        data.map((val) => {
          return setPropertyStreetName((pre) => [
            ...new Set([...pre, val.long_name]),
          ]);
        });
      });

      // property Suburb
      description.map((val) => {
        const data = val.address_components.filter((k) =>
          k.types.includes(
            "locality" || "political" || "administrative_area_level_2"
          )
        );

        data.map((val) => {
          return setPropertySuburb((pre) => [
            ...new Set([...pre, val.long_name]),
          ]);
        });
      });
    } else {
      SetPropertyAddress("");
      setPropertyState("");
      setPropertyStreetName("");
      setPropertySuburb("");
    }
  };

  useEffect(() => {
    dispath(fetchGetEnquiryOptions());
    return () => {
      dispath(removeEnquiryData());
    };
  }, []);

  useEffect(() => {
    scrollToTop();
  }, []);

  //   initial values
  const initialValues = {
    name: profile ? profile.first_name + " " + profile.last_name : "",
    email: profile ? profile.user_email : "",
    phone: profile ? profile.user_mobile : "",
    description: "",
  };

  // check formik validation
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required *"),
    name: Yup.string().required("Required*"),
    phone: Yup.string()
      .matches(/^[0-9]+$/, "Phone number must only contain digits")
      .min(9, "Phone number is too short")
      .max(11, "Phone number is too long"),
    description: Yup.string().required("Required*"),
  });

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    scrollToTop();

    const data = {
      enquiryFor: enquiryFor,
      type: propertyNature,
      lookingFor: lookFor,
      location: propertyAddress,
      suburb: propertySuburb,
      state: propertyState,
      street_name: propertyStreetName,
      propertyType: propertyType,
      budget: price,
      whenNeed: needs,
      propertyCondition: propertyCondition,
      landsize: landSize,
      housesize: houseSize,
      name: values.name,
      email: values.email,
      phone: values.phone,
      description: values.description,
      app_name: "CLASSIESTATE",
    };

    console.log("values formik", data);
    dispath(fetchSendAgentEnquiry(data, token ? token : null));
    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
      onSubmitProps.resetForm({ values: "" });
    }, 1000);
  };

  useEffect(() => {
    message && toast.success(message);
    message && setValue("");
    message && SetPropertyAddress([]);
    message && setPropertyState([]);
    message && setPropertyStreetName([]);
    message && setPropertySuburb([]);
    message && setEnquiryFor("");
    message && setPropertyNature("");
    message && setLookFor("");
    message && setPropertyCondition("");
    message && setPropertyType("");
    message && setPrice("");
    message && setLandSize("");
    message && setHouseSize("");
    message && setNeeds("");

    setTimeout(() => {
      message && dispath(removeEnquiryData());
      message && setpersonalDetailCompo(false);
      message && setStartCompo(true);
    }, 1000);
    setTimeout(() => {
      message && dispath(fetchGetEnquiryOptions());
    }, 2000);
  }, [message]);

  useEffect(() => {
    errMsg && toast.error(errMsg);
    setTimeout(() => {
      errMsg && dispath(removeEnquiryData());
    }, 1000);
    setTimeout(() => {
      errMsg && dispath(fetchGetEnquiryOptions());
    }, 2000);
  }, [errMsg]);

  if (loading) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }

  return (
    <div className="py-16 sm:py-24 bg-gray-50">
      <div className="w-11/12 xl:w-3/4 mx-auto">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          validateOnMount
        >
          {(formik) => (
            <Form>
              {startCompo && (
                <StartComponent
                  setStartCompo={setStartCompo}
                  setLocationCompo={setLocationCompo}
                />
              )}
              {locationCompo && (
                <LocationCompo
                  value={value}
                  setValue={setValue}
                  setStartCompo={setStartCompo}
                  setLocationCompo={setLocationCompo}
                  setEnquiryCompo={setEnquiryCompo}
                  getLocationData={getLocationData}
                />
              )}

              {enquiryCompo && (
                <CommonCompo
                  title="What are you enquiring for?"
                  data={options.enquiry_for}
                  value={enquiryFor}
                  setValue={setEnquiryFor}
                  setCurrentCompo={setEnquiryCompo}
                  setPrevCompo={setLocationCompo}
                  setNextCompo={setNatureComponent}
                />
              )}

              {natureComponent && (
                <CommonCompo
                  title="Property Nature"
                  data={nature?.[0].child}
                  value={propertyNature}
                  setValue={setPropertyNature}
                  setCurrentCompo={setNatureComponent}
                  setPrevCompo={setEnquiryCompo}
                  setNextCompo={setLookingforCompo}
                />
              )}

              {lookingforCompo && (
                <CommonCompo
                  title="What are you looking for?"
                  data={lookingFor?.[0].child}
                  value={lookFor}
                  setValue={setLookFor}
                  setCurrentCompo={setLookingforCompo}
                  setPrevCompo={setNatureComponent}
                  setNextCompo={setPropertyCondCompo}
                />
              )}

              {propertyCondCompo && (
                <NextCommonCompo
                  title="Property Condition"
                  data={options.property_condition}
                  value={propertyCondition}
                  setValue={setPropertyCondition}
                  setCurrentCompo={setPropertyCondCompo}
                  setPrevCompo={setLookingforCompo}
                  setNextCompo={setPropertyTypeCompo}
                  className="mt-10 md:mt-14 flex items-center justify-between gap-x-2 flex-wrap sm:flex-nowrap md:gap-x-5"
                />
              )}

              {propertyTypeCompo && (
                <PropertyType
                  title="Property Type"
                  data={
                    propertyNature === "Commercial"
                      ? options.commercial_property_types
                      : options.residential_property_types
                  }
                  value={propertyType}
                  setValue={setPropertyType}
                  setCurrentCompo={setPropertyTypeCompo}
                  setPrevCompo={setPropertyCondCompo}
                  setNextCompo={setPriceCompo}
                />
              )}

              {priceCompo && (
                <NextCommonCompo
                  title="Price Range (in AUD)"
                  data={
                    enquiryFor === "Rent/Lease"
                      ? options.rent_budget
                      : options.budgets
                  }
                  value={price}
                  setValue={setPrice}
                  setCurrentCompo={setPriceCompo}
                  setPrevCompo={setPropertyTypeCompo}
                  setNextCompo={setLandCompo}
                  className="mt-10 md:mt-14 grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-5 "
                />
              )}

              {landCompo && (
                <NextCommonCompo
                  title="Land Size (in Sqaure meter)"
                  data={options.land_sizes}
                  value={landSize}
                  setValue={setLandSize}
                  setCurrentCompo={setLandCompo}
                  setPrevCompo={setPriceCompo}
                  setNextCompo={setHouseCompo}
                  className="mt-10 md:mt-14 grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-5 "
                />
              )}

              {houseCompo && (
                <NextCommonCompo
                  title="House Size (in Sqaure meter)"
                  data={options.house_sizes}
                  value={houseSize}
                  setValue={setHouseSize}
                  setCurrentCompo={setHouseCompo}
                  setPrevCompo={setLandCompo}
                  setNextCompo={setNeedCompo}
                  className="mt-10 md:mt-14 grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-5 "
                />
              )}

              {needCompo && (
                <NextCommonCompo
                  title="When do you need it?"
                  data={options.when_u_need}
                  value={needs}
                  setValue={setNeeds}
                  setCurrentCompo={setNeedCompo}
                  setPrevCompo={setHouseCompo}
                  setNextCompo={setpersonalDetailCompo}
                  className="mt-10 md:mt-14 grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-5 "
                />
              )}
              {personalDetailCompo && (
                <PersonalDetail
                  setCurrentCompo={setpersonalDetailCompo}
                  setPrevCompo={setNeedCompo}
                />
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AgentEnquiry;
