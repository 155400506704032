import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const loading = () => ({
  type: ActionTypes.AGENT_AGENCIES_LOADING,
});

export const removeAgentAgencyData = () => ({
  type: ActionTypes.REMOVE_AGENT_AGENCY_DATA,
});

export const fetchGetAgentAgencySearchSuggestion =
  (key) => async (dispatch) => {
    await axios
      .get(`${BaseUrl}/agent_search?keyword=${key}`)
      .then(({ data }) => {
        dispatch({
          type: ActionTypes.GET_SEARCH_SUGGESTIONS,
          payload: data,
        });
      });
  };

export const fetchGetAgentSearchResult = (key, page) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/search_agents?name=${key}&page=${page}`)
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_AGENT_SEARCH_RESULT,
        payload: data,
      });
    });
};

export const fetchGetAgencySearchResult = (key, page) => async (dispatch) => {
  await axios
    .get(
      `${BaseUrl}/search_agencies?name=${key}&slug=real-estate&api_from=classiEstate&page=${page}`
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_AGENCIES_SEARCH_RESULT,
        payload: data,
      });
    });
};

export const fetchGetAGentDetail = (id, status, token) => async (dispatch) => {
  await axios
    .get(
      `${BaseUrl}/presets/agent_detail/${id}?${
        status === 1
          ? `&is_completed=1`
          : status === "buy" || status === "rent"
          ? `&property_category=${status}`
          : null
      }`,
      token && token !== undefined
        ? { headers: { Authorization: `Bearer ${token}` } }
        : null
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_AGENT_DETAIL,
        payload: data,
      });
    });
};

export const fetchGetAgencyDetail = (id, page, status) => async (dispatch) => {
  await axios
    .get(
      `${BaseUrl}/presets/agency_detail/${id}?page=${page}realestate_type=Commercial${
        status === 1
          ? `&is_completed=1`
          : status === "buy" || status === "rent"
          ? `&property_category=${status}`
          : null
      }`
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_AGENCY_DETAIL,
        payload: data,
      });
    });
};

export const fetchSendEnquiryToAgent = (body, token) => async (dispatch) => {
  await axios
    .post(
      `${BaseUrl}/send_agent_enquiry`,
      body,
      token && token !== undefined
        ? { headers: { Authorization: `Bearer ${token}` } }
        : null
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.SEND_ENQUIRY_TO_AGENT_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.SEND_ENQUIRY_TO_AGENT_FAIL,
        payload: e.response.data,
      });
    });
};

export const fetchSendEnquiryToAgency = (body, token) => async (dispatch) => {
  await axios
    .post(
      `${BaseUrl}/send_agencies_enquiry`,
      body,
      token && token !== undefined
        ? { headers: { Authorization: `Bearer ${token}` } }
        : null
    )
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.SEND_AGENCY_ENQUIRY_SUCCESS,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.SEND_AGENCY_ENQUIRY_FAIL,
        payload: e.response.data,
      });
    });
};
