import React, { useEffect, useState } from "react";
import { logo } from "../../Asset/Assest";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Button, FormikControl } from "../../Component";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchForgotPassword, removeAuthData } from "../../Redux/Auth/action";
import { scrollToTop } from "../../Component/NavigateTop";
import { toast } from "react-toastify";
import Loading from "../../Component/Loading/Loading";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const [inProgress, setInProgress] = useState(false);

  const message = useSelector((state) => state.auth.forgotMsg, shallowEqual);
  const errMessage = useSelector((state) => state.auth.errMsg, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //   initial values
  const initialValues = {
    email: "",
  };

  // check formik validation
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Required*"),
  });

  // handle submit
  const onSubmit = (values, onSubmitProps) => {
    const body = {
      email: values.email,
      app_name: "CLASSIESTATE",
    };

    localStorage.setItem("email", values.email);
    dispatch(fetchForgotPassword(body));
    setInProgress(true);
    setTimeout(() => {
      onSubmitProps.setSubmitting(false);
    }, 1000);
  };

  useEffect(() => {
    message && toast.success(message);
    message && navigate("/auth/change-password");
    message && setInProgress(false);
    setTimeout(() => {
      message && dispatch(removeAuthData());
    }, 1000);
  }, [message]);

  useEffect(() => {
    errMessage && toast.error(errMessage);
    errMessage && setInProgress(false);
    setTimeout(() => {
      errMessage && dispatch(removeAuthData());
    }, 1000);
  }, [errMessage]);

  useEffect(() => {
    scrollToTop();
  }, []);

  if (inProgress) {
    return (
      <div className="w-full h-screen">
        <Loading />
      </div>
    );
  }
  return (
    <div className="py-24 w-full">
      <div className="w-11/12 sm:w-3/4 lg:w-1/2 xl:w-1/3 mx-auto border bg-white shadow-md p-5 sm:p-7 sm:mt-10 rounded-xl">
        <div className="flex justify-center items-center flex-col">
          <div className="w-1/2 sm:w-5/12 h-8 sm:h-12 xl:h-9">
            <img src={logo} alt="" className="w-full h-full" />
          </div>
          <h4 className="font-semibold text-textPrimary text-2xl mt-6">
            Forgot Password?
          </h4>
        </div>

        <div className="mt-10">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnMount
          >
            {(formik) => (
              <Form>
                <FormikControl
                  label="Email"
                  control="input"
                  name="email"
                  placeholder="Enter Your Email"
                />
                <Button
                  value="Continue"
                  type="submit"
                  className="bg-primary py-3.5 w-full mt-7 rounded-xl text-white"
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
