import axios from "axios";
import { BaseUrl } from "../../Shared/BaseUrl";
import * as ActionTypes from "./actionTypes";

export const profileLoading = () => ({
  type: ActionTypes.PROFILE_LOADING,
});

export const removeProfilesData = () => ({
  type: ActionTypes.REMOVE_PROILES_DATA,
});

export const fetchGetUserProfile = (token) => async (dispatch) => {
  await axios
    .get(`${BaseUrl}/profile_new`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then(({ data }) => {
      dispatch({
        type: ActionTypes.GET_USER_PROFILE,
        payload: data,
      });
    })
    .catch((e) => {
      dispatch({
        type: ActionTypes.FAIL_GET_USER_PROFILE,
        payload: e.response.data,
      });
    });
};
