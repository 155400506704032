import React, { useEffect, useState } from "react";
import { scrollToTop } from "../../Component/NavigateTop";
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  fetchGetPropertyDetails,
  removePropertiesData,
} from "../../Redux/Property/action";
import {
  Button,
  DetailPageMap,
  GridPropertyCard,
  PreviewImages,
  PropertyCard,
  Share,
} from "../../Component";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { BiCopy } from "react-icons/bi";
import { toast } from "react-toastify";
import { BsImageAlt } from "react-icons/bs";
import { RiImageLine } from "react-icons/ri";
import { SiYoutubemusic } from "react-icons/si";
import { FiShare } from "react-icons/fi";
import { BiPhoneCall } from "react-icons/bi";
import { HiOutlineMail } from "react-icons/hi";
import PropertyDetailSkeleton from "./PropertyDetailSkeleton";
import Cookies from "js-cookie";
import {
  fetchAddToWishlist,
  fetchRemoveWishlist,
  removeWishlistData,
} from "../../Redux/Wishlist/action";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  fetchAddInspection,
  fetchDeleteInspection,
  removeProfileData,
} from "../../Redux/Profile/action";
import PropertyEnquiry from "./Popup/PropertyEnquiry";
import { classiBazarLogo } from "../../Asset/Assest";
import { fetchGetSearchResults } from "../../Redux/Search/action";

const PropertyDetail = () => {
  const [showPreview, setShowPreview] = useState(false);
  const [showImages, setShowImages] = useState(false);
  const [showFloors, setShowFloors] = useState(false);
  const [showVideos, setShowVideos] = useState(false);
  const [showPhone, setShowPhone] = useState(false);
  const [indoorFeatures, setIndoorFeatures] = useState();
  const [outdoorFeatures, setOutdoorFeatures] = useState();
  const [postId, setPostId] = useState("");
  const [agentId, setAgentId] = useState("");

  // show more states
  // const [showMoreFeatures, setShowMoreFeatures] = useState(false);
  const [showMoreInspections, setShowMoreInspections] = useState(false);

  const [showShare, setShowShare] = useState(false);
  const [sendEnquiry, setSendEnquiry] = useState(false);

  const [images, setImages] = useState([]);
  const [floors, setFloors] = useState([]);
  const [videos, setVideos] = useState([]);

  let params = new URLSearchParams(document.location.search);
  let category = params.get("property_category");
  let title = params.get("key");

  const search = useLocation().search;
  const location = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    setPostId(new URLSearchParams(search).get("indx"));
  }, [location.search]);

  const token = Cookies.get("token");

  const property = useSelector(
    (state) => state.property.propertyDetail,
    shallowEqual
  );

  const loading = useSelector(
    (state) => state.property.isloading,
    shallowEqual
  );

  const message = useSelector((state) => state.wishlist.message, shallowEqual);
  const errMessage = useSelector(
    (state) => state.wishlist.errMsg,
    shallowEqual
  );

  const insMessage = useSelector(
    (state) => state.profile.message,
    shallowEqual
  );
  const insErrMsg = useSelector((state) => state.profile.errMsg, shallowEqual);

  const dispatch = useDispatch();

  const handleClickClose = () => {
    setShowImages(false);
    setShowFloors(false);
    setShowVideos(false);
  };

  const handleClickImages = () => {
    setShowImages(true);
    setShowFloors(false);
    setShowVideos(false);
    const data = property.images.filter((val) => val.image_type === "Image");
    if (data.length) {
      setImages(data);
    } else {
      setImages([{ message: "Sorry. We don't have any Images to show" }]);
    }
    setFloors([]);
    setVideos([]);
  };

  const handleClickFloor = () => {
    setShowImages(false);
    setShowFloors(true);
    setShowVideos(false);
    const data = property.images.filter(
      (val) => val.image_type === "Floor_plan"
    );

    if (data.length) {
      setFloors(data);
    } else {
      setFloors([{ message: "Sorry. We don't have any Floor plan to show" }]);
    }

    setImages([]);
    setVideos([]);
  };

  const handleClickVideo = () => {
    setShowFloors(false);
    setShowImages(false);
    setShowVideos(true);
    const data = property.post_features.filter(
      (val) => val.title === "Txt Media Type"
    );
    if (data?.[0]?.value?.length) {
      setVideos(data?.[0].value);
    } else {
      setVideos([{ message: "Sorry. We don't have any videos to show" }]);
    }
    setImages([]);
    setFloors([]);
  };

  // property state breadcrum navigation
  const handleClickPropertyState = () => {
    dispatch(
      fetchGetSearchResults(
        category,
        null,
        null,
        null,
        [property?.property_state],
        null,
        null,
        null,
        [],
        null,
        null,
        null,
        null,
        1,
        token
      )
    );

    navigate(
      `/search?property_category=${category}&key=${property?.property_state}`
    );
  };

  // property type breadcrum navigation
  const handleClickPropertyType = () => {
    dispatch(
      fetchGetSearchResults(
        category,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        [property?.property_type],
        null,
        null,
        null,
        null,
        1,
        token
      )
    );

    navigate(`/search?property_category=${category}&key=all`);
  };

  const handleAddToWishlist = (id) => {
    scrollToTop();
    const body = {
      post_id: id,
    };
    dispatch(fetchAddToWishlist(body, token));
  };

  const handleRemoveWishlist = (id) => {
    scrollToTop();
    const body = {
      post_id: id,
    };
    dispatch(fetchRemoveWishlist(body, token));
  };

  const handleAddInspection = (inspectionId, postId) => {
    scrollToTop();
    const body = {
      post_id: postId,
      inspection_id: inspectionId,
    };

    dispatch(fetchAddInspection(body, token));
  };

  const handleRemoveInspection = (id) => {
    scrollToTop();
    const body = {
      inspection_id: id,
    };

    dispatch(fetchDeleteInspection(body, token));
  };

  useEffect(() => {
    scrollToTop();
    postId && dispatch(fetchGetPropertyDetails(postId, token));

    return () => {
      dispatch(removePropertiesData());
    };
  }, [postId]);

  useEffect(() => {
    message && dispatch(removePropertiesData());
    message && toast.success(message);
    message && dispatch(removeWishlistData());
    message && dispatch(fetchGetPropertyDetails(postId, token));
  }, [message && postId]);

  useEffect(() => {
    insMessage && dispatch(removePropertiesData());
    insMessage && dispatch(removeProfileData());
    insMessage && toast.success(insMessage);
    insMessage && dispatch(removeWishlistData());
    insMessage && dispatch(fetchGetPropertyDetails(postId, token));
  }, [insMessage && postId]);

  useEffect(() => {
    errMessage && dispatch(removePropertiesData());
    errMessage && toast.error(errMessage);
    errMessage && dispatch(removeWishlistData());
    errMessage && dispatch(fetchGetPropertyDetails(postId, token));
  }, [errMessage && postId]);

  useEffect(() => {
    insErrMsg && dispatch(removePropertiesData());
    insErrMsg && dispatch(removeProfileData());
    insErrMsg && toast.error(insErrMsg);
    insErrMsg && dispatch(removeWishlistData());
    insErrMsg && dispatch(fetchGetPropertyDetails(postId, token));
  }, [insErrMsg && postId]);

  useEffect(() => {
    const data =
      property.post_features &&
      property.post_features.find((val) => val.title === "Indoor Features");

    const data2 =
      property.post_features &&
      property.post_features.find((val) => val.title === "Outdoor Features");

    const video =
      property.post_features &&
      property?.post_features?.filter((val) => val.title === "Txt Media Type");

    setIndoorFeatures(data);
    setOutdoorFeatures(data2);
    setVideos(video?.[0]?.value);
  }, [property]);

  useEffect(() => {
    scrollToTop();
  }, []);

  if (loading) {
    return (
      <div className="w-full h-screen py-14 sm:py-24">
        <PropertyDetailSkeleton />
      </div>
    );
  }
  return (
    <div className="py-14 sm:py-24 w-full relative ">
      {showShare && <Share setShowShare={setShowShare} />}
      {sendEnquiry && (
        <PropertyEnquiry
          setToggle={setSendEnquiry}
          data={property?.agents}
          postId={postId}
          category={category}
        />
      )}
      {showShare || sendEnquiry ? (
        <div
          className="absolute w-full h-full top-0 bg-black opacity-10 z-30"
          onClick={() => {
            setShowShare(false);
            setSendEnquiry(false);
          }}
        ></div>
      ) : null}

      {showPreview && (
        <div className="w-11/12 lg:w-10/12 mx-auto h-[80vh] sm:h-[75vh] lg:h-[105vh] xl:h-[100vh] mt-5 rounded-md z-40 bg-textPrimary shadow-md border py-5">
          <PreviewImages
            images={
              showImages
                ? images
                : showFloors
                ? floors
                : showVideos
                ? videos
                : property.images
            }
            setShowPreview={setShowPreview}
            handleClickImages={handleClickImages}
            handleClickFloor={handleClickFloor}
            handleClickVideo={handleClickVideo}
            handleClickClose={handleClickClose}
            showImages={showImages}
            showFloors={showFloors}
            showVideos={showVideos}
          />
        </div>
      )}

      {!showPreview && (
        <>
          {/* Bread crum */}
          <div className="flex items-center flex-wrap gap-x-1 sm:gap-x-3 mt-5 w-11/12 xl:w-3/4 mx-auto">
            <p className="text-textSecondary capitalize text-xs sm:text-sm underline mt-2">
              <Link to={`${category === "rent" ? `/rent` : "/"}`}>
                {category}
              </Link>
            </p>
            <MdKeyboardArrowRight size={15} className="text-textSecondary" />
            <p
              className="text-textSecondary text-xs sm:text-sm capitalize underline cursor-pointer mt-2"
              onClick={handleClickPropertyState}
            >
              {property.property_state}
            </p>
            <MdKeyboardArrowRight size={15} className="text-textSecondary" />
            <p
              className="text-textSecondary text-xs sm:text-sm capitalize underline cursor-pointer mt-2"
              onClick={handleClickPropertyType}
            >
              {property.property_type}
            </p>
            <MdKeyboardArrowRight size={15} className="text-textSecondary" />
            <p className="text-textPrimary capitalize text-xs sm:text-sm font-medium mt-2">
              {title}
            </p>
          </div>

          {/* image section */}
          <div className="w-11/12 xl:w-3/4 mx-auto h-[20vh] sm:h-[40vh] lg:h-[70vh] gap-x-5 mt-5 grid grid-cols-12">
            <div className="col-span-7 lg:col-span-8 h-[20vh] sm:h-[40vh] lg:h-[70vh] w-full">
              <img
                src={property.images?.[0]?.image_name}
                alt=""
                className="w-full h-full object-cover cursor-pointer"
                onClick={() => setShowPreview(true)}
              />
            </div>
            <div className="col-start-8 lg:col-start-9 col-end-13 h-[20vh] sm:h-[40vh] lg:h-[70vh] relative">
              <div className="flex gap-y-2 flex-col h-full">
                {property.images?.slice(1, 3).map((val, ind) => {
                  return (
                    <img
                      src={val.image_name}
                      alt=""
                      className={`w-full h-full object-cover cursor-pointer overflow-hidden ${
                        ind === 2 ? "bg-black" : ""
                      }`}
                      key={ind}
                      onClick={() => setShowPreview(true)}
                    />
                  );
                })}
              </div>

              {property.images && property.images.length > 3 && (
                <div
                  className="absolute bottom-[15%] left-1/3 z-30 cursor-pointer "
                  onClick={() => setShowPreview(true)}
                >
                  <h2 className="font-semibold text-2xl sm:text-4xl md:text-6xl text-white">
                    +{property.images.length - 3}
                  </h2>
                </div>
              )}
            </div>
          </div>

          <div className="w-fit mt-6 sm:hidden sm:mt-0 mx-auto rounded-full border bg-white py-2 px-5 flex-wrap flex items-center gap-x-2 sm:gap-x-7">
            <div className="flex items-center gap-x-5">
              <div
                className={`flex items-center w-fit gap-x-3 cursor-pointer ${
                  showImages ? "text-primary" : "text-textSecondary"
                }`}
                onClick={() => {
                  return setShowPreview(true), handleClickImages();
                }}
              >
                <BsImageAlt className="text-xs " />
                <p className="font-medium text-xs ">Photos</p>
              </div>

              <div className="border-r-2 h-5"></div>
            </div>

            <div className="flex items-center gap-x-5">
              <div
                className={`flex items-center w-fit gap-x-3 cursor-pointer ${
                  showFloors ? "text-primary" : "text-textSecondary"
                }`}
                onClick={() => {
                  return setShowPreview(true), handleClickFloor();
                }}
              >
                <RiImageLine className="text-xs" />
                <p className="font-medium text-xs">Floor Plan</p>
              </div>

              <div className="border-r-2 h-5"></div>
            </div>

            <div className="flex items-center gap-x-5">
              <div
                className={`${
                  showVideos ? "text-primary" : "text- text-textSecondary"
                } flex items-center w-fit gap-x-3 cursor-pointer`}
              >
                <SiYoutubemusic className="text-xs" />
                <p className="font-medium text-xs ">Videos</p>
              </div>
            </div>
          </div>

          {/* content section */}
          <div className="w-11/12 xl:w-3/4 mt-10 mx-auto sm:gap-x-5 grid grid-cols-12">
            {/* description section */}
            <div className="col-span-12 sm:col-span-7 lg:col-span-8 h-full">
              <div className="flex justify-between items-center gap-x-5 md:gap-x-0 flex-wrap">
                <h6 className="text-primary font-medium text-base xl:text-xl">
                  {property.title}
                </h6>
                {property.is_wishlist ? (
                  <div
                    className="flex items-center w-fit px-5 gap-x-2 border rounded-xl py-2.5 mt-4 cursor-pointer transition duration-1000 ease-in-out hover:bg-gray-100"
                    onClick={() => handleRemoveWishlist(property.post_id)}
                  >
                    <AiFillHeart className="text-red-500 text-base xl:text-2xl" />
                    <p className="text-sm sm:text-lg text-textPrimary">
                      Saved Property
                    </p>
                  </div>
                ) : (
                  <div
                    className="flex items-center w-fit px-5 gap-x-2 border rounded-xl py-2.5 mt-4 cursor-pointer transition duration-1000 ease-in-out hover:bg-gray-100"
                    onClick={() => handleAddToWishlist(property.post_id)}
                  >
                    <AiOutlineHeart className="text-textSecondary text-base xl:text-2xl" />
                    <p className="text-sm sm:text-lg text-textPrimary">
                      Save Property
                    </p>
                  </div>
                )}
              </div>

              <h4 className="text-textPrimary mt-5 font-semibold text-2xl xl:text-3xl">
                {property.price !== "0"
                  ? property.price_type !== "2"
                    ? `$${property.price} ${
                        property.property_category === "rent"
                          ? `/ ${property.price_type2}`
                          : ""
                      }`
                    : `$${property.price} - $${property.to_price}  ${
                        property.property_category === "rent"
                          ? `/  ${property.price_type2}`
                          : ""
                      }`
                  : ""}
              </h4>
              <div className="mt-4 text-textSecondary flex items-center gap-x-5">
                <p className="text-base xl:text-lg">{property.ad_address}</p>
                <div
                  className="bg-gray-100 p-2 rounded-md cursor-pointer"
                  onClick={() => {
                    navigator.clipboard.writeText(property.ad_address);
                    toast.success("Copied to clipboard");
                  }}
                >
                  <BiCopy className="text-lg" />
                </div>
              </div>

              {/* icons */}
              <div className="flex flex-wrap items-center gap-x-5 mt-3">
                {property.ads_data &&
                  property.ads_data.map((item, i) => {
                    return item.value === null ||
                      item.value === "0" ||
                      item.value === " m sq." ? null : (
                      <div className="flex items-center gap-x-2 mt-4" key={i}>
                        <img src={item.img} alt="parking" className="w-5 h-5" />
                        <h6 className="text-textPrimary text-base lg:text-lg flex items-center">
                          {item.label === "Area"
                            ? item.value.replace("m sq.", " ")
                            : item.value}
                          <p
                            className={` ${
                              item.label === "Area" ? "block" : "hidden"
                            }`}
                          >
                            {" "}
                            m <sup>2</sup>
                          </p>
                        </h6>
                      </div>
                    );
                  })}

                <h6 className="border-l px-2 capitalize text-textSecondary mt-4">
                  {property.property_type}
                </h6>
              </div>

              <hr className="w-full mt-10" />

              {/* property description */}
              <div className="mt-10">
                <h4 className="text-textPrimary text-xl md:text-3xl font-semibold">
                  Property Description
                </h4>
                <hr className="w-20 border border-primary mt-4" />
                <p className="mt-5 text-textSecondary">
                  {property.description}
                </p>
              </div>

              <hr className="w-full mt-10" />

              {/* property features */}
              {indoorFeatures?.value?.length ||
              outdoorFeatures?.value?.length ? (
                <div className="mt-10">
                  <h4 className="text-textPrimary text-xl md:text-3xl font-semibold">
                    Property Features
                  </h4>
                  <hr className="w-20 border border-primary mt-4" />
                </div>
              ) : null}

              {indoorFeatures && indoorFeatures?.value?.length ? (
                <>
                  <div className="mt-10">
                    <h1 className="text-2xl font-semibold">Indoor features</h1>
                    <div
                      className={`overflow-hidden grid sm:grid-cols-2 h-fit`}
                    >
                      {indoorFeatures &&
                        indoorFeatures.value.map((val, i) => {
                          return (
                            <div
                              className="mt-5 flex items-center gap-x-5"
                              key={i}
                            >
                              <div className="w-5 h-5 lg:w-7 lg:h-7">
                                <img
                                  src={val.image_name}
                                  alt=""
                                  className="w-full h-full"
                                />
                              </div>
                              <p className="text-textPrimary text-base lg:text-lg">
                                {val.title}
                              </p>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <hr className="w-full mt-7" />
                </>
              ) : null}

              {outdoorFeatures && outdoorFeatures?.value?.length ? (
                <>
                  <div className="mt-7">
                    <h1 className="text-2xl font-semibold">Outdoor features</h1>
                    <div
                      className={`overflow-hidden grid sm:grid-cols-2 h-fit`}
                    >
                      {outdoorFeatures &&
                        outdoorFeatures.value.map((val, i) => {
                          return (
                            <div
                              className="mt-5 flex items-center gap-x-5"
                              key={i}
                            >
                              <div className="w-5 h-5 lg:w-7 lg:h-7">
                                <img
                                  src={val.image_name}
                                  alt=""
                                  className="w-full h-full"
                                />
                              </div>
                              <p className="text-textPrimary text-base lg:text-lg">
                                {val.title}
                              </p>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <hr className="w-full mt-10" />
                </>
              ) : null}

              {/* Inspections */}
              <div className="mt-10">
                <h4 className="text-textPrimary text-xl md:text-3xl font-semibold">
                  Inspections
                </h4>
                <hr className="w-20 border border-primary mt-4" />

                <div
                  className={`w-full lg:w-11/12 xl:w-3/4 border overflow-y-auto rounded-lg mt-10 ${
                    property.inspection_times?.length > 3 || showMoreInspections
                      ? "h-80"
                      : "h-fit"
                  }`}
                >
                  {property.inspection_times &&
                    property.inspection_times.map((val, i) => {
                      return (
                        <div
                          className="flex flex-wrap p-5 items-center justify-between border-b pb-5"
                          key={i}
                        >
                          <div>
                            <h6 className="text-lg font-medium text-textPrimary">
                              {new Date(val.inspection_date).toDateString()}
                            </h6>

                            <p className="text-textSecondary mt-2">
                              {val.start_hr}:{val.start_min}-{val.end_hr}:
                              {val.end_min}
                            </p>
                          </div>
                          <div className="flex items-center gap-x-5 mt-5 lg:mt-0 ">
                            {val.inspection_added ? (
                              <Button
                                value="In Plan"
                                handleClick={() =>
                                  handleRemoveInspection(val.id)
                                }
                                className="w-fit px-5 py-3 border transition duration-1000 ease-in-out hover:bg-green-50 text-green-700 border-green-700 rounded-lg cursor-pointer"
                              />
                            ) : (
                              <Button
                                value="+ Add to Plan"
                                handleClick={() =>
                                  handleAddInspection(val.id, val.post_id)
                                }
                                className="w-fit px-5 py-3 border transition duration-1000 ease-in-out hover:bg-gray-100 text-textPrimary border-gray-200 rounded-lg cursor-pointer"
                              />
                            )}

                            {/* <div className="p-2 border rounded-md bg-gray-100 cursor-pointer">
                              <RxCalendar className="text-textPrimary text-2xl" />
                            </div> */}
                          </div>
                        </div>
                      );
                    })}

                  {property.inspection_times &&
                  property.inspection_times.length > 3 ? (
                    <div
                      className="flex flex-wrap items-center gap-x-2 p-5 border cursor-pointer"
                      onClick={() =>
                        setShowMoreInspections(!showMoreInspections)
                      }
                    >
                      <p className="text-primary font-medium">
                        {showMoreInspections
                          ? "Show fewer"
                          : "Show more Inspections"}
                      </p>
                      <MdKeyboardArrowDown className="text-primary text-lg cursor-pointer" />
                    </div>
                  ) : null}

                  <h6 className="text-lg text-textPrimary p-5 rounded-b-lg bg-gray-50 border">
                    Can’t find the time that suit you?{" "}
                    <span
                      className="text-primary font-medium cursor-pointer"
                      onClick={() => navigate("/agent-enquiry")}
                    >
                      Make an Enquiry
                    </span>
                  </h6>
                </div>
              </div>

              <hr className="w-full mt-10" />

              {/* property video */}
              {videos && videos.length ? (
                <div className="mt-10">
                  <h4 className="text-textPrimary text-xl md:text-3xl font-semibold">
                    Property Video
                  </h4>
                  <hr className="w-20 border border-primary mt-4" />

                  {videos.map((val) => {
                    return (
                      <div
                        key={val.image_id}
                        className="w-full h-[40vh] lg:h-[55vh] px-1 mt-10 rounded-xl"
                      >
                        <iframe
                          src={val.link_url}
                          width="100%"
                          height="100%"
                          frameborder="0"
                          allowfullscreen
                        ></iframe>
                      </div>
                    );
                  })}
                </div>
              ) : null}

              {/* property location */}
              <div className="mt-10">
                <h4 className="text-textPrimary text-xl md:text-3xl font-semibold">
                  Property Location
                </h4>
                <hr className="w-20 border border-primary mt-4" />

                <div className="w-full lg:w-11/12 xl:w-3/4 rounded-2xl mt-10">
                  <DetailPageMap
                    latitude={property.latitude}
                    longitude={property.longitude}
                  />
                </div>
              </div>
            </div>

            {/* property agent section */}
            <div className="mt-4 sm:mt-0 col-span-12 sm:col-start-8 lg:col-start-9 col-end-13 h-full relative">
              <div className="sticky top-28">
                <div className="w-fit mt-6 hidden sm:mt-0 mx-auto rounded-full border bg-white py-2 px-5 flex-wrap sm:flex items-center gap-x-2 2xl:gap-x-7">
                  <div className="flex items-center gap-x-2 2xl:gap-x-5">
                    <div
                      className={`flex items-center w-fit gap-x-3 cursor-pointer ${
                        showImages ? "text-primary" : "text-textSecondary"
                      }`}
                      onClick={() => {
                        return setShowPreview(true), handleClickImages();
                      }}
                    >
                      <BsImageAlt className="text-[0.6rem] xl:text-xs " />
                      <p className="font-medium text-[0.6rem] xl:text-xs ">
                        Photos
                      </p>
                    </div>

                    <div className="border-r-2 h-5"></div>
                  </div>

                  <div className="flex items-center gap-x-2 2xl:gap-x-5">
                    <div
                      className={`flex items-center w-fit gap-x-3 cursor-pointer ${
                        showFloors ? "text-primary" : "text-textSecondary"
                      }`}
                      onClick={() => {
                        return setShowPreview(true), handleClickFloor();
                      }}
                    >
                      <RiImageLine className="text-[0.6rem] xl:text-xs" />
                      <p className="font-medium text-[0.6rem] xl:text-xs">
                        Floor Plan
                      </p>
                    </div>

                    <div className="border-r-2 h-5"></div>
                  </div>

                  <div className="flex items-center gap-x-2 2xl:gap-x-5">
                    <div
                      className={`${
                        showVideos ? "text-primary" : "text- text-textSecondary"
                      } flex items-center w-fit gap-x-3 cursor-pointer`}
                      onClick={() => {
                        return setShowPreview(true), handleClickVideo();
                      }}
                    >
                      <SiYoutubemusic className="text-[0.6rem] xl:text-xs" />
                      <p className="font-medium text-[0.6rem] xl:text-xs ">
                        Videos
                      </p>
                    </div>
                  </div>
                </div>

                {/* agents */}
                <div className="w-full h-full border rounded-2xl mt-12">
                  <div
                    className="w-full rounded-t-xl h-12 flex justify-center items-center"
                    style={{ background: `${property.company_color}` }}
                  >
                    <div className="w-24 h-7">
                      {property.company_logo ? (
                        <img
                          src={property.company_logo}
                          alt="logo"
                          className="w-full h-full object-contain cursor-pointer"
                          onClick={() =>
                            navigate(
                              `/agency-detail?indx=${property?.post_owner?.userid}`
                            )
                          }
                        />
                      ) : (
                        <img
                          src={classiBazarLogo}
                          alt="logo"
                          className="w-full h-full"
                        />
                      )}
                    </div>
                  </div>

                  <div className="mt-3 p-3">
                    <p className="text-textPrimary text-sm">
                      {property.ad_address}
                    </p>

                    {/* icons */}
                    <div className="flex flex-wrap items-center gap-x-2 ">
                      {property.ads_data &&
                        property.ads_data.map((item, i) => {
                          return item.value === null ||
                            item.value === "0" ||
                            item.value === " m sq." ? null : (
                            <div
                              className="flex items-center gap-x-2 mt-3"
                              key={i}
                            >
                              <img
                                src={item.img}
                                alt="parking"
                                className="w-4 h-4"
                              />
                              <h6 className="text-textPrimary text-base lg:text-lg flex items-center">
                                {item.label === "Area"
                                  ? item.value.replace("m sq.", " ")
                                  : item.value}
                                <p
                                  className={` ${
                                    item.label === "Area" ? "block" : "hidden"
                                  }`}
                                >
                                  {" "}
                                  m <sup>2</sup>
                                </p>
                              </h6>
                            </div>
                          );
                        })}

                      <h6 className="border-l pl-2 capitalize text-xs xl:text-sm text-textSecondary mt-3">
                        {property.property_type}
                      </h6>
                    </div>
                    <hr className="w-full mt-8" />
                    {/* buttons */}
                    <div className="flex items-center justify-between gap-x-7 mt-4">
                      {property.is_wishlist ? (
                        <div
                          className="flex items-center w-full px-5 justify-center gap-x-2 border rounded-full py-2.5 mt-4 cursor-pointer transition duration-1000 ease-in-out hover:bg-gray-100"
                          onClick={() => handleRemoveWishlist(property.post_id)}
                        >
                          <AiFillHeart className="text-red-500 text-base xl:text-2xl" />
                          <p className="text-sm sm:text-base text-textPrimary">
                            Saved
                          </p>
                        </div>
                      ) : (
                        <div
                          className="flex items-center w-full px-5 justify-center gap-x-2 border rounded-full py-2.5 mt-4 cursor-pointer transition duration-1000 ease-in-out hover:bg-gray-100"
                          onClick={() => handleAddToWishlist(property.post_id)}
                        >
                          <AiOutlineHeart className="text-textSecondary text-base xl:text-2xl" />
                          <p className="text-sm sm:text-base text-textPrimary">
                            Save
                          </p>
                        </div>
                      )}
                      <div
                        onClick={() => {
                          scrollToTop();
                          setShowShare(true);
                        }}
                        className="flex items-center w-full px-5 justify-center gap-x-2 border rounded-full py-2.5 mt-4 cursor-pointer transition duration-1000 ease-in-out hover:bg-gray-100"
                      >
                        <FiShare className="text-textSecondary text-base xl:text-2xl" />
                        <p className="text-sm sm:text-base text-textPrimary">
                          Share
                        </p>
                      </div>
                    </div>
                    <hr className="mt-7" />

                    <div className=" mt-7 rounded-2xl">
                      {property?.agents?.map((val, i) => {
                        return (
                          <div
                            className="flex items-center justify-between p-2 flex-wrap border rounded-xl mt-4 py-3"
                            key={val.id}
                          >
                            <div className="flex items-center gap-x-4 w-full">
                              <div className="w-12 h-12 rounded-full overflow-hidden">
                                {val.image_url ? (
                                  <img
                                    src={val.image_url}
                                    alt=""
                                    className="w-full h-full"
                                  />
                                ) : (
                                  <img
                                    src={classiBazarLogo}
                                    alt=""
                                    className="w-full h-full rounded-full"
                                  />
                                )}
                              </div>
                              <div className="flex justify-between items-center w-3/4 lg:hidden">
                                <h6 className="text-textPrimary font-medium text-sm lg:text-base">
                                  {val?.first_name} {val?.last_name}{" "}
                                </h6>
                                <a href={`tel: ${val.mobile}`}>
                                  <div className="flex lg:hidden items-center gap-x-2 mt-2 cursor-pointer">
                                    <BiPhoneCall className="text-textSecondary cursor-pointer text-2xl" />
                                  </div>
                                </a>
                              </div>

                              <div className="lg:flex flex-col hidden">
                                <h6
                                  className="text-textPrimary font-medium text-sm lg:text-base cursor-pointer"
                                  onClick={() =>
                                    navigate(`/agent-detail?indx=${val.id}`)
                                  }
                                >
                                  {val?.first_name} {val?.last_name}{" "}
                                </h6>

                                <div
                                  className="hidden lg:flex items-center gap-x-2 mt-2 cursor-pointer"
                                  onClick={() => {
                                    setShowPhone(showPhone);
                                    setAgentId(i);
                                  }}
                                >
                                  <BiPhoneCall className="text-textSecondary cursor-pointer text-2xl" />
                                  {agentId === i ? (
                                    <p className="">{val.mobile}</p>
                                  ) : (
                                    <p className="">
                                      {val.mobile.slice(0, 7)}****
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <hr className="mt-7" />
                    {property.ad_status === "4" ? null : (
                      <Button
                        value="Enquire about this property"
                        handleClick={() => {
                          scrollToTop();
                          setSendEnquiry(true);
                        }}
                        className="w-full py-3 bg-primary text-white mt-7 rounded-lg"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* similar posts */}
          <div className="w-11/12 xl:w-3/4 mx-auto mt-16">
            {property?.similar_posts && property?.similar_posts.length ? (
              property.similar_posts.length > 3 ? (
                <PropertyCard
                  title="Similar Properties"
                  data={property.similar_posts}
                  handleAddToWishlist={handleAddToWishlist}
                  handleRemoveWishlist={handleRemoveWishlist}
                />
              ) : (
                <div className="w-full grid grid-cols-1 pb-5 sm:grid-cols-2 lg:grid-cols-3 gap-x-3 xl:gap-x-6">
                  {property.similar_posts.map((val) => {
                    return (
                      <GridPropertyCard
                        key={val.id}
                        title="Similar Properties"
                        data={val}
                        handleAddToWishlist={handleAddToWishlist}
                        handleRemoveWishlist={handleRemoveWishlist}
                      />
                    );
                  })}
                </div>
              )
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};

export default PropertyDetail;
