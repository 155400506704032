import React from "react";
import { classiBazarLogo } from "../../Asset/Assest";
import { GrClose } from "react-icons/gr";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import { shallowEqual, useSelector } from "react-redux";
import Loading from "../Loading/Loading";

const MobileNotification = ({
  showNotification,
  notification,
  setShowNotification,
  setPage,
  page,
}) => {
  const has_next_page = useSelector(
    (state) => state.notification.has_next_page,
    shallowEqual
  );

  return (
    <div
      className={`block lg:hidden bg-white fixed left-0 top-0 pb-10 h-full w-full md:w-3/4 shadow-2xl backdrop-blur-2xl transform z-50 ease-in-out duration-500 overflow-y-auto  ${
        showNotification ? "-translate-x-0" : "-translate-x-full"
      }`}
    >
      <div className="w-full flex items-center justify-between border-b sticky top-0 h-16 bg-white p-5">
        <h5 className="font-medium text-base md:text-xl">Notifications</h5>
        <div
          className="hover:border cursor-pointer hover:bg-gray-100 rounded-full p-2"
          onClick={() => setShowNotification(false)}
        >
          <GrClose className="text-xl sm:text-2xl md:text-3xl" />
        </div>
      </div>
      <div className="p-5 w-full h-full">
        {notification && notification.length ? (
          <>
            {notification.map((val) => {
              return (
                <div
                  className="flex mt-5 gap-x-5 w-full cursor-pointer hover:shadow-md overflow-hidden  border rounded-lg p-2"
                  key={val.id}
                >
                  <div className="w-10 h-10">
                    <img
                      src={classiBazarLogo}
                      alt=""
                      className="w-full h-full rounded-full"
                    />
                  </div>

                  <div className="w-full overflow-hidden">
                    <h6 className="text-base font-medium truncate">
                      {val.title}
                    </h6>
                    <p className="text-textSecondary mt-2 text-sm">
                      {val.body}
                    </p>
                  </div>
                </div>
              );
            })}
            <div className="w-full mt-7 pb-5 flex items-center gap-x-7 justify-center">
              {has_next_page ? (
                <div
                  className=" bg-primary rounded-full p-5 w-fit text-white cursor-pointer"
                  onClick={() => {
                    setPage(page + 1);
                  }}
                >
                  <AiOutlineArrowDown />
                </div>
              ) : null}
              {page !== 1 ? (
                <div
                  className=" bg-primary rounded-full p-5 w-fit text-white cursor-pointer"
                  onClick={() => {
                    setPage(page - 1);
                  }}
                >
                  <AiOutlineArrowUp />
                </div>
              ) : null}
            </div>
          </>
        ) : (
          <h5 className="flex justify-center items-center w-full h-full">
            No Notification Yet
          </h5>
        )}
      </div>
    </div>
  );
};

export default MobileNotification;
