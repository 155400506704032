import React from "react";
import { filterIcon } from "../../Asset/Assest";

const Filter = ({ className, handleClick }) => {
  return (
    <div className={className} onClick={handleClick}>
      <img src={filterIcon} alt="" className="w-5 h-5 text-primary" />
      <p>Filter</p>
    </div>
  );
};

export default Filter;
