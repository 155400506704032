import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ConfigureStore } from "./Redux/configureStore";
import { Provider } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
const store = ConfigureStore();
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="227976197901-kknh014mauq2uf26pij0qpub24gkc3r5.apps.googleusercontent.com">
      <Provider store={store}>
        <App />
      </Provider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);
